import React, { Component } from 'react'
import ReactFullpage from '@fullpage/react-fullpage'
import { Link, graphql } from 'gatsby'
import SEO from '../components/seo'
import Nav from '../components/nav'
import BackIcon from '../components/back'

class StoryTemplate extends Component {
  render() {
    const story = this.props.data.markdownRemark
    const { previous, next } = this.props.pageContext
    
    return (
      <>
        <SEO title={story.frontmatter.title} description={story.excerpt}>
          <link rel="canonical" href={`${story.fields.slug}`} />
        </SEO>
        <Nav align="text-center">
          <div className="col text-left">
            {/* <Link to="/story">&larr; Back to Stories</Link> */}
            <Link className="target fill-dark" to="/story" style={{height:"30px"}}><BackIcon /></Link>
          </div>
        </Nav>
        <ReactFullpage
          // fullpage options
          licenseKey={'C1912978-A39F4CE4-9F6AC28B-DFB573DA'}
          sectionsColor={['#e8e7e3']}
          lazyLoading
          autoScrolling={false}
          scrollBar={false}
          fitToSection={false}

          render={() => (
            <ReactFullpage.Wrapper>
              <section className="section" key="story">
                <article className="container mt-md-5 p-5" key={story.id} id={story.id}>
                  <header className="mt-5">
                    {story.frontmatter.description ? (
                      <h1>{story.frontmatter.description}</h1>
                    ) : (
                      <h1>{story.frontmatter.title}</h1>
                    )}
                  </header>
                  <main dangerouslySetInnerHTML={{ __html: story.html }} />

                  <hr />

                  <nav className="my-auto">
                    <ul className="pagination justify-content-between my-3">
                      <li className="page-item text-left">
                        {previous && (
                          <Link to={previous.fields.slug} rel="prev">
                            &larr; Previous: {previous.frontmatter.title}
                          </Link>
                        )}
                      </li>
                      <li className="page-item text-right">
                        {next && (
                          <Link to={next.fields.slug} rel="next">
                            Next: {next.frontmatter.title} &rarr;
                          </Link>
                        )}
                      </li>
                    </ul>
                  </nav>
                </article>

                {/* <nav className="nav container-fluid justify-content-center px-md-5 py-3">
                  <Link to="/story">&larr; Back to Stories</Link>
                </nav> */}
              </section>
            </ReactFullpage.Wrapper>
          )}
        />
      </>
    )
  }
}

export default StoryTemplate

export const query = graphql`
  query StoryBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 300)
      html
      fields {
        slug
      }
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
      }
    }
  }
`